/* Hide scrollbar by default */
body::-webkit-scrollbar {
  width: 12px;
}

body::-webkit-scrollbar-track {
  background: #0e1111;
}

body::-webkit-scrollbar-thumb {
  background-color: transparent; /* Make scrollbar thumb transparent */
  border-radius: 20px;
  border: 3px solid #0e1111;
}

/* Firefox */
body {
  scrollbar-width: thin;
  scrollbar-color: transparent #0e1111;
}

/* Class to show scrollbar */
.show-scrollbar::-webkit-scrollbar-thumb {
  background-color: #888; /* Visible scrollbar thumb */
}

.show-scrollbar {
  scrollbar-color: #888 #0e1111;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #0e1111;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #0e1111;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.coin img {
  animation: jump 2s infinite;
  animation-delay: calc(.2s * var(--i));
}

.logo-coin img  {
  height: 300px;
  width: 300px;
  animation-name: stretch;
  animation-duration: 3.5s; 
  animation-timing-function: ease-out; 
  animation-delay: 0;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-fill-mode: none;
  animation-play-state: running;
}

@keyframes stretch {
  0% {
    transform: scale(.8);
  }
  70% {
  }
  100% {
    transform: scale(1.3);
  }
}

@keyframes jump {

  0%,
  100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }

  50% {
    transform: translateY(0);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}
.waviy {
  position: relative;
  -webkit-box-reflect: below -20px linear-gradient(transparent, rgba(0, 0, 0, .2));
}

.waviy span {
  /* font-family: 'Alfa Slab One', cursive; */
  position: relative;
  display: inline-block;

  /* color: #f8f4f4; */
  color: white;
  /* text-i */
  text-shadow: 0 0 6px;
  /* -webkit-text-stroke-width: 3px; */
  /* -webkit-text-stroke-color: rgb(112, 109, 109); */
  /* text-transform: uppercase; */
  animation: waviy 4s infinite;
  animation-delay: calc(.1s * var(--i));

}

@keyframes waviy {

  0%,
  40%,
  100% {
    transform: translateY(0)
  }

  20% {
    transform: translateY(-30px)
  }
}

@keyframes fillText {
  from {
    color: #FFF; /* Or your current text color */
  }
  to {
    color: #48cae4; /* Or whichever color you want to fill with */
  }
}

.text-animation {
  animation: fillText 7s forwards;
}

@keyframes pulse {
  0%, 100% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.05);
    opacity: 0.7;
  }
}

.animate-pulse {
  animation: pulse 2s infinite;
}

.button-gradient {
  background: linear-gradient(to right, #56CCF2 50%, #2F80ED 100%);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 30px;
  cursor: pointer;
  transition: background-position 1s ease;
  background-size: 200% 100%;
  background-position: right bottom;
}

.button-gradient:hover {
  background-position: left bottom;
}

@keyframes typing {
  from { width: 0; }
  to { width: 100%; }
}

@keyframes blink-caret {
  from, to { border-color: transparent; }
  50% { border-color: black; }
}

.typewriter-holder {
  width: 280px;
  position: relative;
  display: block;
  margin: auto;
}

.path {
  transform-style: preserve-3d;
}

.mask {
  mask-image: url(../src/assets/mask.png);
}

.perspective {
  perspective:2000px;
  will-change: transform;
}

.cube_anim {
  transform: rotateZ(calc(var(--i, 0) * 5deg)) rotateY(70deg);
  animation: cube 14400ms linear infinite;
  animation-delay: calc(300ms * var(--i));
  animation-timing-function: linear;
}

@keyframes cube {
  0%,
  100% {
    background-image: linear-gradient(
      180deg,
      #eb4899 0%,
      #d53bab 15.62%,
      rgba(197, 49, 184, 0.15) 53.12%,
      #9d19da 83.33%,
      #7400fc 100%
    );
  }
  50% {
    background-image: linear-gradient(
      180deg,
      #7400fc 100% #9d19da 83.33%,
      rgba(197, 49, 184, 0.15) 53.12%,
      #d53bab 15.62%,
      #eb4899 0%
    );
  }

  from {
    transform: rotateZ(calc(var(--i, 0) * 5deg)) rotateY(70deg);
  }
  to {
    transform: rotateZ(calc(var(--i, 0) * 5deg - 360deg)) rotateY(70deg);
  }

  0%,
  10%,
  50%,
  60%,
  100% {
    opacity: 0;
  }
  15%,
  45%,
  65%,
  95% {
    opacity: 1;
  }
}

footer {
  position: relative; /* Add this if it's not already there */
  /* ... other styles ... */
}

.scrollToTopButton {
  position: absolute;
  right: 10px; /* Adjust as needed for your layout */
  bottom: 50px; /* Adjust as needed for your layout */
  z-index: 10; /* Ensure it's above other content */
}